import { Button, Fab, IconButton, Link, TextField } from "@material-ui/core";
import { MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { AddCircle, CloudUpload, Delete, Save, Visibility } from "@material-ui/icons";
import { Alert } from '@material-ui/lab';
import React, { useRef, useState } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { v4 as uuidv4 } from 'uuid';
import logo from "../assets/images/new_ss_logo.png";
import { UploadS3Files } from "../services/S3UplodService";
import SimIdeaService from "../services/SimIdeaService";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Loader from "./loader";
import SimWizardRow from "./public/SimWizardRow";
import formLabelsTheme from './generic/FormLabelsThem';
import { HELP_PAGE_LINK } from "../utils/Constant";

const PublicSimCreator = () => {

    const initialState = [{ idx: 0, id: Math.random(), name: "", comments: "", type: "text" }];
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [rows, setRows] = useState(initialState);
    const [, forceUpdate] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));
    const [showError, setShowError] = useState(false);
    const [isValidEail, setIsValidEmail] = useState(false);
    const [simIdea, setSimIdea] = useState(
        {
            id: uuidv4(),
            clientName: '',
            clientEmail: '',
            title: '',
            desc: '',
            documents: [],
            createdAt: ''
        }
    );
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        msg: "",
        type: ""
    });

    const onFileChange = e => {
        const docTypes = ['mp4', 'm4v', 'm4a', 'mp3', 'png', 'jpg', 'jpeg', 'docx', 'doc', 'pdf', 'xlsx'];
        let isError = false;
        let errorMsg = "";
        let tempFiles = files;
        for (let i = 0; i < e.target.files.length; i++) {
            const newFile = e.target.files[i];
            var ext = newFile.name.substr(newFile.name.lastIndexOf('.') + 1);
            if (docTypes.indexOf(ext.toLowerCase()) !== -1) {
                tempFiles[tempFiles.length] = newFile;
            } else {
                isError = true;
                errorMsg += "[ " + newFile.name + " ]\n";
            }
        }

        if (isError) {
            setOpenAlert(true);
            setAlertProps({ ...alertProps, msg: errorMsg, type: 'info' });
        }
        e.target.value = '';
        setFiles([...tempFiles]);
    };

    const handleChange = (name, value, idx) => {
        const updatedRows = rows;
        const track = rows[idx];
        updatedRows[idx] = { ...track, [name]: value };
        setRows(updatedRows);
    };

    const handlePersonalInfoChange = (e) => {
        const name = e.target.name;
        if (name === 'clientEmail') {
            setSimIdea({ ...simIdea, [name]: e.target.value });
            validateEmail(e.target.value);
        } else {
            setSimIdea({ ...simIdea, [name]: e.target.value });
        }
    };

    const addAnotherRow = () => {
        let updatedRows = rows;
        if (rows[updatedRows.length - 1].idx !== 0) {
            let closeBtn = document.getElementById(rows[updatedRows.length - 1].idx);
            closeBtn.style.display = "none";
        }

        updatedRows[updatedRows.length] = {
            idx: rows[updatedRows.length - 1].idx + 1,
            id: Math.random(),
            name: "",
            comments: "",
            type: "text"
        };
        setRows([...updatedRows]);
    };

    const deleteRow = (idx) => {
        if (idx - 1 !== 0) {
            let closeBtn = document.getElementById(idx - 1);
            closeBtn.style.display = "inline-flex";
        }
        let newRow = rows;
        const index = newRow.findIndex(a => a.idx === idx);
        if (index === -1) return;
        newRow.splice(index, 1);
        setRows([...newRow]);
    };

    const deleteFile = (fileNmae) => {
        let newFiles = files;
        const index = newFiles.findIndex(a => a.name === fileNmae);
        if (index === -1) return;
        newFiles.splice(index, 1);
        setFiles([...newFiles]);
    };

    const previewFile = (file) => {
        const url = URL.createObjectURL(file);
        document.getElementById("filePreview").setAttribute("href", url);
        document.getElementById("filePreview").click();
    };

    const validateEmail = (email) => {
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w\w+)+$/;
        if (reg.test(email)) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    }

    const handleSaveClick = () => {
        if (simpleValidator.current.allValid()) {
            setLoading(true);
            if (files.length > 0) {
                uploadFiles(files, simIdea.id);
            } else {
                saveIdea({ ...simIdea, tracks: rows });
            }

        } else {
            simpleValidator.current.showMessages();
            setShowError(true);
        }
    }

    const saveIdea = (data) => {
        SimIdeaService().saveSimIIdea(data)
            .then((response) => {
                if (parseInt(response.status) === 200) {
                    setLoading(false);
                    setIsSubmitted(true);
                } else {
                    console.error("Error: while saving sim idea!");
                }
            }).catch((e) => {
                console.log('Error: could not save idea', e.message);
            });
    };

    const uploadFiles = (files, id) => {
        const promises = [];

        files.forEach(file => {
            const uploadTask = UploadS3Files(file, `sim_ideas/${id}/`);
            promises.push(uploadTask);
        });

        Promise.all(promises)
            .then((uploadedFiles) => {

                let documents = [];
                uploadedFiles.forEach(file => {
                    documents.push({ fileName: file.fileKey.substring(file.fileKey.lastIndexOf('/') + 1), url: file.fileKey });
                });

                const simIdeaWithDocs = {
                    ...simIdea,
                    tracks: rows,
                    documents
                };

                saveIdea(simIdeaWithDocs);
            })
            .catch(err => console.error(err));
    };

    return (
        <div>
            <div className="col-6 offset-3 text-center">
                <a href="/" style={{ display: "none" }} id="filePreview" target={"_blank"}>Open File</a>
                {loading &&
                    <div className="overlay">
                        <Loader />
                    </div>
                }
                <img src={logo} alt="SchoolSims Logo" className="logo"  />
                {isSubmitted &&
                    <h3 className="mt-3">Thank you for submitting your simulation idea! Someone from our team will be in contact shortly!</h3>
                }
                {!isSubmitted &&
                    <>
                        <p>To submit your simulation idea please fill out the form below.</p>
                        <h3 className="text-left">General Info</h3>
                        <MuiThemeProvider theme={formLabelsTheme}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    error={showError && simIdea.clientName === '' && true}
                                    variant="outlined"
                                    required
                                    id="clientName"
                                    name="clientName"
                                    label="Name"
                                    value={simIdea.clientName}
                                    onChange={handlePersonalInfoChange}
                                    size={"small"}
                                    style={{ marginBottom: 15, fontSize: "12%" }}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 200 }}
                                    helperText={simpleValidator.current.message('Client Name', simIdea.clientName, 'required')}
                                />
                                <TextField
                                    error={showError && !isValidEail && true}
                                    variant="outlined"
                                    required
                                    name="clientEmail"
                                    label="Email"
                                    value={simIdea.clientEmail}
                                    onChange={handlePersonalInfoChange}
                                    size={"small"}
                                    style={{ marginBottom: 15, fontSize: "12%" }}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 250 }}
                                    helperText={simpleValidator.current.message('Client Email', simIdea.clientEmail, 'required|email')}
                                />
                                <TextField
                                    error={showError && simIdea.title === '' && true}
                                    variant="outlined"
                                    required
                                    id="title"
                                    name="title"
                                    label="Sim Title"
                                    value={simIdea.title}
                                    onChange={handlePersonalInfoChange}
                                    size={"small"}
                                    style={{ marginBottom: 15, fontSize: "12%" }}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 250 }}
                                    helperText={simpleValidator.current.message('Sim Title', simIdea.title, 'required')}
                                />
                                <TextField
                                    error={showError && simIdea.desc === '' && true}
                                    variant="outlined"
                                    required
                                    id="desc"
                                    name="desc"
                                    label="Sim Description"
                                    size={"small"}
                                    value={simIdea.desc}
                                    onChange={handlePersonalInfoChange}
                                    minRows={3}
                                    maxRows={5}
                                    multiline={true}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 5000 }}
                                    helperText={simpleValidator.current.message('Sim Description', simIdea.desc, 'required')}
                                />
                                {rows.map((r) => (
                                    <SimWizardRow
                                        key={r.id} {...r}
                                        handleChange={handleChange}
                                        handleDelete={deleteRow}
                                        simpleValidator={simpleValidator}
                                        showError={showError}
                                        totalTracks={initialState.length}
                                    />
                                ))}
                                <h3>
                                    Add Another Track{" "}
                                    <Tooltip title="Add Track" placement="right-center">
                                        <IconButton onClick={() => addAnotherRow()}>
                                            <AddCircle color="primary" fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </h3>
                                <input type="file"
                                    accept={".pdf,.doc,.docx,.xlsx,audio/mp3,audio/m4a,video/mp4,video/m4v,image/png,image/jpg,image/jpeg"}
                                    className="mb-2"
                                    name="docs"
                                    id="docs"
                                    onChange={onFileChange}
                                    style={{ width: '30%', display: 'none' }}
                                    multiple
                                />
                                <h3>
                                    File cabinet{" "}
                                    <label htmlFor="docs">
                                        <Tooltip title="Choose Files" placement="right-center">
                                            <IconButton variant="contained" color="primary" component="span">
                                                <CloudUpload color="primary" fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </label>
                                </h3>
                                {openAlert &&
                                    <div className="text-left mb-2">
                                        <Alert severity={alertProps.type} onClose={() => { setOpenAlert(false); }}>
                                            <strong>Following files are not supported!</strong>
                                            <h4>{alertProps.msg}</h4>
                                        </Alert>
                                    </div>
                                }
                                <ul className="list-group mb-2">
                                    {files.map((file) => (
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <div className="col-10 text-break text-left">
                                                <h5>{file.name}</h5>
                                            </div>
                                            <div>
                                                <Tooltip title="Preview File" placement="bottom-start">
                                                    <IconButton onClick={() => previewFile(file)}>
                                                        <Visibility color="primary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete File" placement="bottom-start">
                                                    <IconButton onClick={() => deleteFile(file.name)}>
                                                        <Delete color="secondary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <h3>
                                    <Button onClick={() => handleSaveClick()} color="primary" variant="contained">
                                        Submit Simulation Idea
                                        <Save fontSize="large" />
                                    </Button>
                                </h3>
                            </form>
                        </MuiThemeProvider>
                    </>
                }
            </div>
            <Fab style={{ position: 'fixed', right: 10, bottom: 10 }}>
                <Tooltip title="Frequently Asked Question">
                    <Link href={HELP_PAGE_LINK} target="_blank">
                        <HelpOutlineIcon style={{ fontSize: 24, color: '#4a4a4a' }} />
                    </Link>
                </Tooltip>
            </Fab>
        </div >
    );
};


export default PublicSimCreator;