import React, { useContext, useEffect, useState } from "react";
import { PlayerContext } from "../../context/PlayerContext";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import SimSessionService from "../../services/SimSessionService";
import logo from "../../assets/images/new_ss_logo.png";
import playFromStart from "../../assets/images/btnEndReset_icon.svg";
import playFromPrevState from "../../assets/images/btnNext_icon.svg";
import CustomizedIcons from "./CustomizedIcons";
import { SSOContext } from "../../context/SSOContext";
import { handleKeyDown, setSimState } from "../../utils/utils";

const PromptPlaySimulation = () => {
  const history = useHistory();
  const {
    setPlayerState,
    simSessionState,
    linkId,
    playerState,
    setSimSessionState,
    setLinkId,
  } = useContext(PlayerContext);
  const userContext = useContext(UserContext);
  const ssoContext = useContext(SSOContext);
  const [isPlayFromStart, setIsPlayFromStart] = useState("");

  useEffect(() => {
    const handlePopstate = () => {
      history.push("/loading");
    };
    window.addEventListener("popstate", handlePopstate);
  }, []);

  useEffect(() => {
    if (isPlayFromStart === false) {
      let playerStateFromSB = simSessionState?.simSessionData
        ? JSON.parse(simSessionState?.simSessionData)?.playerState
        : null;
      if (!playerStateFromSB) {
        setSimState(ssoContext, userContext, setPlayerState, setLinkId);
      } else {
        if (ssoContext.accountType == "Higher Ed") {
          playerStateFromSB = {
            ...playerStateFromSB,
            showPreSimFeedbackPage: false,
            showPreSimQR: false,
          };
        } else {
          const showPreSimFeedbackPage =
            playerStateFromSB.showPreSimFeedbackPage;
          const showPreSimQR = playerStateFromSB.showPreSimQR;
          playerStateFromSB =
            showPreSimFeedbackPage || showPreSimQR
              ? {
                  ...playerStateFromSB,
                  showPreSimFeedbackPage: ssoContext.showPreSimFeedbackPage,
                  showPreSimQR: ssoContext.showPreSimQR,
                }
              : playerStateFromSB;
        }
        playerStateFromSB = {
          ...playerStateFromSB,
          showPostSimFeedbackPage: ssoContext.showPostSimFeedbackPage,
          showPostSimQR: ssoContext.showPostSimQR,
        };
        setPlayerState(playerStateFromSB);
      }
      setTimeout(() => history.push("/play"), 100);
    } else if (isPlayFromStart == true) {
      setSimSessionState(null);
      const simSession = {
        userId: userContext.user.id,
        simId: playerState.simId,
        linkId: linkId === "" ? null : linkId,
      };
      SimSessionService()
        .simNotPlayAgain(simSession)
        .then((res) => {
          console.debug(res);
          setTimeout(() => history.push("/play"), 100);
        });
    }
  }, [isPlayFromStart]);
  return (
    <div className="loader prompt-bg-color">
      <div className="loadMessage">
        <div>
          <img src={logo} style={{ width: 400 }} alt="SchoolSims Logo" />
        </div>
        <div className="session-popup">
          <p className="text-dark">Open session found</p>
          <div className="d-flex">
            <div className="mr-2">
              <span
                onClick={() => {
                  setIsPlayFromStart(true);
                }}
                tabIndex={0}
                onKeyDown={(e) => handleKeyDown(e, setIsPlayFromStart, true)}
                aria-label="Play from Start"
              >
                <CustomizedIcons
                  src={playFromStart}
                  width={true}
                  title="Play from Start"
                />
              </span>
            </div>
            <div>
              <span
                onClick={() => {
                  setIsPlayFromStart(false);
                }}
                tabIndex={0}
                onKeyDown={(e) => handleKeyDown(e, setIsPlayFromStart, false)}
                aria-label="Play from Previous"
              >
                <CustomizedIcons
                  src={playFromPrevState}
                  width={true}
                  title="Play from Previous"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptPlaySimulation;
