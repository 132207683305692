import React, { useState, useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Loader from "react-loader-spinner";
import logo from "../assets/images/new_sb_logo.png";
import portal_logo from "../assets/images/new_ss_logo.png";
import "../styles/style.css";
import queryString from "query-string";
import { SSOContext } from "../context/SSOContext";
import { UserContext } from "../context/UserContext";
import userService from "../services/UserService";
import SimService from "../services/SimService";
import { setSimPlayData } from "../utils/utils";

const Login = () => {
  const ssoContext = useContext(SSOContext);
  const userContext = useContext(UserContext);

  const [err, setErr] = useState();
  const [isSimPlayed, setIsSimPlayed] = useState(false);
  const [tokenType, setTokenType] = useState(null);
  const [isTokenExpired, setTokenExpired] = useState(false);

  //automatically redirect if auth'ed
  const login = () => {
    const user = document.getElementById("username").value;
    const pwd = document.getElementById("pwd").value;
    const credentials = { email: user, password: pwd };
    if (validate(user, pwd)) {
      userService()
        .userLogin(credentials)
        .then((response) => {
          if (response.status === 401) {
            setErr("Username or password are incorrect, please try again.");
            return false;
          }
          userContext.setUser(response.user);
          userContext.setToken(response.token);
          localStorage.setItem("user", JSON.stringify(response.user));
          localStorage.setItem("token", response.token);
        })
        .catch((err) => {
          console.log(err);
          setErr("Username or password are incorrect, please try again.");
        });
    }
  };

  const getTokenType = () => {
    const params = queryString.parse(window.location.search);
    let tokenType = null;
    if (params["t"]) {
      tokenType = "t";
    } else if (params["s"]) {
      tokenType = "s";
    }
    return tokenType;
  };

  const validate = (user, pwd) => {
    if (!pwd) {
      setErr("Please enter your password");
      return false;
    }
    if (!user) {
      setErr("Please enter your username");
      return false;
    }

    return true;
  };

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    ssoContext.setLinkAuditId(params.linkAuditId);
    if (params["t"]) {
      ssoContext.setLinkAuditId(params.linkAuditId);
      let data = {
        jwt: params["t"],
        isAdRetrieve: false,
        isAccreditationClient: false,
      };
      setTokenType("t");
      userService()
        .verifyJwtSimToken(data)
        .then((response) => {
          if (response.status === 200) {
            console.log(
              `Login Resp => AccId=[${response.data.accountId}] and LinkId=[${response.data.linkId} and LinkAuditId=[${response.data.linkAuditId}]`
            );
            setSimPlayData(
              userContext,
              ssoContext,
              response.data,
              params["linkAuditId"]
            );
            this.props.history.push("/loading?id=" + response.data.simId);
          } else {
            console.error("Error: while verifying JWT Token Or Token invalid!");
            setTokenExpired(true);
          }
        })
        .catch((e) => {
          console.error(e.message);
          setTokenExpired(true);
        });
    } else if (params["s"]) {
      let simId = params["s"];
      setTokenType("s");
      SimService()
        .getSimById(simId)
        .then((response) => {
          if (response.status === 200 && !response.data.published) {
            userService()
              .verifyPublicUser()
              .then((response) => {
                userContext.setUser(response.user);
                userContext.setToken(response.token);
                localStorage.setItem("user", JSON.stringify(response.user));
                localStorage.setItem("token", response.token);
                ssoContext.setSsoSimId(simId);
                this.props.history.push("/loading?id=" + simId);
              })
              .catch((e) => {
                console.error(e.message);
              });
          } else {
            setTokenType(null);
            setIsSimPlayed(response.data.published);
          }
        })
        .catch((e) => {
          console.error(e.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (tokenType === "t" || tokenType === "s") {
    return (
      <div className="loader">
        <div className="loadMessage">
          <div>
            <img
              src={portal_logo}
              style={{ width: 500 }}
              alt="School Sim Logo"
            />
          </div>
          {!isTokenExpired && <h1>Authenticating ...</h1>}
          {!isTokenExpired && (
            <Loader type="Bars" color="#272370" height={200} width={200} />
          )}
          {isTokenExpired && (
            <h2>
              Session has been expired. Please reopen the link to play
              simulation.
            </h2>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {!isSimPlayed && getTokenType() == null && (
          <div className="login">
            <div className="login_container">
              <div>
                <img src={logo} alt="School Sim Logo" className="login_img" />
              </div>
              <TextField name="username" id="username" />
              <TextField name="pwd" id="pwd" type="password" />
              {err && <Alert severity="error">{err}</Alert>}
              <Button
                name="login"
                onClick={login}
                color="primary"
                variant="contained"
              >
                <LockOpenIcon /> Login
              </Button>
            </div>
          </div>
        )}
        {isSimPlayed && (
          <>
            <div className="bg-heading pt-3 pb-3">
              <img
                src={portal_logo}
                className="login_img"
                alt="School Sim Logo"
              />
            </div>
            <div className="text-center">
              <h2>Link expired please contact to the administrator!</h2>
            </div>
          </>
        )}
      </>
    );
  }
};

export default Login;
