import React, { useContext, useEffect, useState } from "react";
import { Roles } from "../utils/userUtil";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { SSOContext } from "../context/SSOContext";
import { PlayerContext } from "../context/PlayerContext";
import { UserContext } from "../context/UserContext";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import logo from "../assets/images/new_ss_logo.png";
import UserService from "../services/UserService";
import SimService from "../services/SimService";
import { LibraryContext } from "../context/LibraryContext";
import SimSessionService from "../services/SimSessionService";
import { setSimPlayData } from "../utils/utils";

const SimLoading = () => {
  const history = useHistory();

  const ssoContext = useContext(SSOContext);
  const playerContext = useContext(PlayerContext);
  const userContext = useContext(UserContext);
  const libraryContext = useContext(LibraryContext);
  const [role, setRole] = useState(null);
  const [isSimPlayed, setIsSimPlayed] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isTokenExpired, setTokenExpired] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);

  useEffect(() => {
    if (playerContext.playerState.simId && playerContext.isStoreSimSession) {
      const simSession = {
        userId: userContext.user.id,
        simId: playerContext.playerState.simId,
        linkId: playerContext.linkId === "" ? null : playerContext.linkId,
        accountId: ssoContext.accountId,
      };
      SimSessionService()
        .retrieveSimSessionData(simSession)
        .then((res) => {
          playerContext.setSimSessionState(res ? res : null);
          if (res) {
            history.push("/promptPlay");
          } else {
            setTimeout(() => history.push("/play"), 3000);
          }
        });
    }
  }, [playerContext.playerState]);

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    if (ssoContext.ssoSimId !== null) {
      playerContext.getAndPlaySim(
        true,
        ssoContext.ssoSimId,
        ssoContext.linkId ? ssoContext.linkId : "",
        ssoContext.linkAuditId,
        ssoContext.accountId,
        ssoContext.portalSimCode,
        ssoContext.showPreSimFeedbackPage,
        ssoContext.showPostSimFeedbackPage,
        ssoContext.showPreSimQR,
        ssoContext.showPostSimQR
      );
    }
    if (!isApiCalled) {
      if (params["t"]) {
        let data = {
          jwt: params["t"],
          isAdRetrieve: true,
          isAccreditationClient: true,
        };
        setShowLoader(true);
        UserService()
          .verifyJwtSimToken(data)
          .then((response) => {
            if (response.status === 200) {
              console.debug("SimLoading Response Data => ", response.data);
              setSimPlayData(
                userContext,
                ssoContext,
                response.data,
                params["linkAuditId"]
              );
            } else {
              console.error(
                "Error: while verifying JWT Token Or Token invalid!"
              );
              setTokenExpired(true);
            }
          })
          .catch((e) => {
            console.error(e.message);
            setTokenExpired(true);
          });
      } else if (params["s"]) {
        let isSimPlayed = false;
        if (userContext.user) {
          let userRole = userContext.user.roleByRole.roleName;
          SimService()
            .getSimById(params["s"])
            .then((response) => {
              if (response.status === 200) {
                let sim = response.data;
                libraryContext.addSimToLibrary(sim);
                const simStatus = sim.published;
                if (userRole === Roles.Admin) {
                  isSimPlayed = true;
                } else if (userRole === Roles.Client && !simStatus) {
                  isSimPlayed = true;
                } else if (!simStatus) {
                  isSimPlayed = true;
                }
                setIsSimPlayed(isSimPlayed);
                setRole(userRole);
                if (isSimPlayed) {
                  ssoContext.setSsoSimId(params["s"]);
                }
              }
            });
        }
      }
      setIsApiCalled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoContext]);

  return (
    <>
      {showLoader && (
        <div className="loader">
          <div className="loadMessage">
            <div>
              <img src={logo} style={{ width: 500 }} alt="SchoolSims Logo" />
            </div>
            {!isTokenExpired && <h1>Loading Simulation...</h1>}
            {!isTokenExpired && (
              <Loader type="Bars" color="#272370" height={200} width={200} />
            )}
            {isTokenExpired && (
              <h2>
                Session has been expired. Please reopen the link to play
                simulation.
              </h2>
            )}
          </div>
        </div>
      )}

      {role && role === Roles.Client && !isSimPlayed && (
        <>
          <div className="bg-heading pt-3 pb-3">
            <img src={logo} style={{ width: 300 }} alt="SchoolSims Logo" />
          </div>
          <div className="text-center">
            <h2>Link expired please contact to the administrator!</h2>
          </div>
        </>
      )}
    </>
  );
};

export default SimLoading;
