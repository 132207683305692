import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  DialogActions,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import SimPlayedSummaryService from "../../services/SimPlayedSummaryService";
import SimpleReactValidator from "simple-react-validator";
import { PersonalInfoMessage, PersonalInfoNote } from "../../utils/utils";

const emailLength = 50;
const othersFieldLength = 100;
const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: "black !important",
    marginTop: "-12px !important",
  },
}));

const initialLocalState = {
  firstName: "",
  lastName: "",
  email: "",
  assignmentSubmission: "",
};

const PersonalInfoDialog = (props) => {
  const classes = useStyles();
  const [isFormSubmitted, setisFormSubmitted] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [personalInfo, setPersonalInfo] = useState(initialLocalState);
  const firstNameErrorMessage = validator.current.message(
    "First Name",
    personalInfo.firstName.trim(),
    "required"
  );
  const lastNameErrorMessage = validator.current.message(
    "Last Name",
    personalInfo.lastName.trim(),
    "required"
  );
  const emailErrorMessage = validator.current.message(
    "Email",
    personalInfo.email.trim(),
    "required|email"
  );
  const assignmentSubmissionErrorMessage =
    props.accountId != null &&
    validator.current.message(
      "assignmentSubmission",
      personalInfo.assignmentSubmission,
      "required",
      {
        messages: {
          required: "Please choose one option 'Yes' or 'No' to continue.",
        },
      }
    );

  const inputHandler = (e) => {
    const newState = { [e.target.name]: e.target.value };
    const lengthRestriction =
      e.target.name === "email" ? emailLength : othersFieldLength;
    if (e.target.value.trim().length <= lengthRestriction) {
      setPersonalInfo((prevState) => ({ ...prevState, ...newState }));
    }
  };

  const closeDialog = () => {
    props.setIsOpen(false);
  };

  const cancelHandler = () => {
    closeDialog();
  };

  const saveHandler = () => {
    props.setIsLoading(true);
    setisFormSubmitted(true);
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      return;
    }
    const simPlayedSummary = {
      ...props.simPlayedSummary,
      firstName: personalInfo.firstName.trim(),
      lastName: personalInfo.lastName.trim(),
      email: personalInfo.email.trim(),
      assignmentSubmission:
        props.accountId && personalInfo.assignmentSubmission,
      playType: localStorage.getItem("playType")
        ? localStorage.getItem("playType")
        : "",
      userId: parseInt(props.userId),
      simPlayUUID: props.simPlayUUID,
      accreditationAccount: Boolean(
        props.accountId == 0 ||
          (props.accreditationClient === "false"
            ? false
            : props.accreditationClient)
      ),
    };
    props.setSimPlayedSummary(simPlayedSummary);
    closeDialog();
    SimPlayedSummaryService()
      .saveSimPlayedSummaryResult(simPlayedSummary)
      .then((response) => {
        if (!response.status === 200) {
          console.error(
            "Error: while saving sim played summary",
            response.message
          );
        }
        //download pdf
        setTimeout(() => {
          props.pdf(props.isSavePdf);
        }, 100);
      })
      .catch((e) => {
        props.setIsLoading(false);
        console.error(e.message);
      });
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "escapeKeyDown" || reason !== "backdropClick") {
            props.setIsOpen(false);
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="color-blue">
          {props.title}:
          <p className="person-info-instructions">
            <PersonalInfoMessage />
          </p>
        </DialogTitle>
        <DialogContent dividers style={{ marginTop: "-24px" }}>
          <div className="row">
            {props.accountId != null && (
              <>
                <div className="col-12 ">
                  <p className="notes notes-margin">
                    <PersonalInfoNote />
                  </p>
                  <FormControl
                    required
                    error={isFormSubmitted && assignmentSubmissionErrorMessage}
                  >
                    <FormLabel className={classes.formLabel}>
                      If you are playing this for an assignment or project, we
                      encourage you to play the simulation multiple times. Would
                      you like this to be considered your official submission
                      for feedback reporting purposes?
                    </FormLabel>
                    <RadioGroup
                      aria-label="assignmentSubmission"
                      value={personalInfo.assignmentSubmission}
                      name="assignmentSubmission"
                      onChange={inputHandler}
                      className={classes.formLabel}
                      row
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText style={{ marginTop: "-10px" }}>
                      {assignmentSubmissionErrorMessage}
                    </FormHelperText>
                  </FormControl>
                </div>
              </>
            )}

            <div className="col-12 mb-3 mt-3">
              <TextField
                variant="outlined"
                error={isFormSubmitted && firstNameErrorMessage}
                id="first_name"
                name="firstName"
                label="First Name"
                value={personalInfo.firstName}
                required
                fullWidth
                onChange={inputHandler}
                helperText={firstNameErrorMessage}
              />
            </div>
            <div className="col-12 mb-3">
              <TextField
                variant="outlined"
                error={isFormSubmitted && lastNameErrorMessage}
                id="last_name"
                name="lastName"
                label="Last Name"
                value={personalInfo.lastName}
                required
                fullWidth
                onChange={inputHandler}
                helperText={lastNameErrorMessage}
              />
            </div>
            <div className="col-12 mb-3">
              <TextField
                variant="outlined"
                error={isFormSubmitted && emailErrorMessage}
                id="email"
                name="email"
                label="Email"
                value={personalInfo.email}
                required
                fullWidth
                onChange={inputHandler}
                helperText={emailErrorMessage}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveHandler} variant="contained" color="primary">
            {props.save}
          </Button>
          <Button onClick={cancelHandler} variant="contained" color="secondary">
            {props.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PersonalInfoDialog;
