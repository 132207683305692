import { UserContext } from "../context/UserContext";
import React, { useEffect, useContext } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Avatar, Tooltip } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import avatarBlank from "../../src/assets/images/blank_avatar.jpeg";
import logo from "../assets/images/new_sb_logo.png";
import { HELP_PAGE_LINK } from "../utils/Constant";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginTop: 10,
    marginBottom: 10,
  },
  menu: {
    marginTop: 35,
    minWidth: 100,
  },
  menuItem: {
    "&:hover": {
      textDecoration: "none",
      color: "red !important",
    },
    "&:visited": {
      textDecoration: "none",
      color: "black",
    },
  },
  helpIcon: {
    fontSize: 30,
    color: "black !important",
  },
}));

const TopNavMenu = () => {
  const { logout } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // user details get here
  });

  return (
    <div className="row nav-bar">
      <div className="logo_container">
        <img src={logo} alt="School Sim Logo" className="top_nav_img" />
      </div>
      <div className="d-flex">
        <div className="help-icon-header">
          <Tooltip title="Frequently Asked Question" className="content-body">
            <Link href={HELP_PAGE_LINK} target="_blank">
              <HelpOutlineIcon className={classes.helpIcon} />
            </Link>
          </Tooltip>
        </div>
        <Tooltip title="Edit Profile">
          <Avatar
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="circular"
            color="primary"
            onClick={handleClick}
            src={avatarBlank}
            className={classes.avatar}
            style={{ height: 38, width: 38, marginTop: 7, marginBottom: 8 }}
          />
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem
            style={{ minWidth: 150 }}
            className={classes.menuItem}
            onClick={logout}
            selected
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default TopNavMenu;
