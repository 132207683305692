import logo from "./../../assets/images/new_ss_logo.png";
import React from "react";

const TopNavForPublic = () => {
  return (
    <div className="topNav_public">
      <div className="row nav-bar">
        <div className="logo_container">
          <img src={logo} alt="School Sim Logo" />
        </div>
      </div>
    </div>
  );
};

export default TopNavForPublic;
